f.application_container label {
  display: block;
}

.application_container .inner_container {
  padding: 35px;
}

.application_container .app_input input,
input#employee_name {
  width: 305px;
  margin-right: 15px;
}
.application_container .app_input .small_input {
  width: 68px;
}
/*.mailing_address.app_input {
    margin-bottom: 0px;
}*/
.formField p {
  margin-bottom: 5px;
}
p.checkbox_div {
  float: left;
  width: 100%;
}
.checkbox_div input[type="checkbox"] {
  float: left;
  width: auto;
  margin-bottom: 10px;
  margin-right: 10px;
}
p.sub_label {
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
  margin-bottom: 5px;
}
.education_div input,
.txtDate,
.FullName,
.referance_div input,
.license_div input,
.personal_referance_div input {
  width: 305px !important;
  margin-right: 15px;
  margin-bottom: 15px;
}
#addition_info {
  width: 100%;
  min-height: 100px;
}
#addition_info {
  max-width: 100% !important;
  width: 100% !important;
  min-height: 100px;
}
.small_dropdown {
  width: 160px !important;
}
.application_container textarea {
  min-height: 90px !important;
  height: 40px;
  width: 100% !important;
  margin-bottom: 15px;
}
.personal_referance_div {
  margin-top: 10px;
}
/*.application_container textarea {
    padding: 10px;
}*/
input.anticipated_date,
#reorting_location {
  width: 250px;
}
input.submit_appln {
  width: auto;
  float: right;
  color: #fff;
  padding: 5px 20px;
  background: #3f7e39;
  border: 1px solid #3f7e39;
  text-transform: capitalize;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
}
.file_div_outrt {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.file_div {
  -ms-flex: 1 0;
  flex: 1 0;
  padding: 5px;
}
.file_div:first-child {
  padding-left: 0;
}
.file_div:last-child {
  padding-right: 0;
}
.file_div input {
  margin-bottom: 5px;
}
.react-datepicker__day,
.react-datepicker__day-name {
  width: 2.7rem;
  margin: 0.6rem;
  font-size: 12px;
}
.personal_referance_div input {
  width: 330px !important;
}
.formField input[errors="error"],
.formField select[errors="error"],
.formField textarea[errors="error"] {
  border-color: #ff0000 !important;
  background: #ffdcdc !important;
}
.file_div_outrt .green {
  text-decoration: none;
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  margin-left: 2px;
}
.file_div_outrt .green:hover,
.file_div_outrt .green:focus {
  color: #3f7e39;
}
.referance_div > div,
.license_div > div {
  display: inline-block;
}
.paragragh {
  margin-bottom: 20px;
}
.new_application .inner_container {
  padding: 50px;
}
.new_application .formField {
  padding: 0;
}
.new_application .goback {
  padding-bottom: 20px;
}
.application_container.outer_application {
  /* position: relative;
    top: 20px; */
}
.outer_application .content_div,
.outer_application .formField {
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.outer_application .page_title {
  margin-bottom: 40px;
}
.applnSubmitStatus {
  /* background: #f1f1f1;
    width: 35%;
    height: 250px;
    margin: 50px auto;
    text-align: center; */
  /* position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translateX(-50%); */
  background: #f1f1f1;
  width: 35%;
  height: 250px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  /* background: #f00; */
  position: relative;
  top: 100px;
}
.applnSubmitStatus div {
  padding: 75px 20px;
}
.applnSubmitStatus h1 {
  font-family: "proxima_nova_altbold", Georgia, sans-serif;
  color: #3f7e39;
}
.applnSubmitStatus p {
  font-family: "Proxima Nova Semibold", Georgia, sans-serif;
}

/*** Header & Footer - STARTS ***/
.outer_application_form header .header-top {
  width: 100%;
  height: 150px;
  float: left;
  background: url(../images/header_bg.jpg) no-repeat center top #050903;
}

.outer_application_form nav {
  width: 100%;
  height: 65px;
  float: left;
  position: relative;
  border-top: 1px solid #333;
  background: #191919; /* Old browsers */ /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #191919),
    color-stop(100%, #030303)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    #191919 0%,
    #030303 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    #191919 0%,
    #030303 100%
  ); /* Opera 11.10+ */ /* IE10+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#191919),
    to(#030303)
  );
  background: linear-gradient(to bottom, #191919 0%, #030303 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#191919', endColorstr='#030303',GradientType=0 ); /* IE6-9 */
  border-radius: 0;
}
.outer_application_form nav .menu {
  height: 64px;
  float: right;
}
.outer_application_form nav .menu li {
  height: 64px;
  float: left;
  font: 400 20px/21px "Roboto Condensed", sans-serif;
  color: #ffe887;
}
.outer_application_form nav .menu li a {
  height: 64px;
  padding: 0 43px;
  float: left;
  font: 300 18px/60px "Roboto Condensed", sans-serif;
  color: #ffe887;
  text-transform: uppercase;
  margin: 0 1px;
}
header .header-top .logo {
  position: relative;
  left: 0;
  top: 17px;
  z-index: 1;
}
.outer_application_form .wrapper {
  width: auto;
  max-width: 1134px;
  margin: 0 auto;
}
header .header-top .contact-section .buttons {
  width: 152px;
  float: right;
  margin: 0 0 0 10px;
  border: 1px solid #575a57;
  padding: 9px 10px;
  font: 400 14px/16px "Sintony", sans-serif;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  font-size: 15px;
}
header .header-top .contact-section {
  width: 500px;
  float: right;
  margin: 13px 0 0;
}
header .header-top .contact-section .tel {
  width: 100%;
  float: right;
  margin: 34px 0 0 0;
  font: 400 34px/35px "Roboto Condensed", sans-serif;
  color: #ffe887;
  text-align: right;
}
header .header-top .container {
  padding: 0;
  width: 100%;
}

footer {
  width: 100%;
  float: left;
}
footer .footer-contnr {
  width: 100%;
  height: auto;
  min-height: 400px;
  float: left;
  background: #010101 url(../images/footer_bg.jpg) no-repeat center top;
}
footer section.container {
  padding: 0;
}
footer .copyright-contnr {
  width: 100%;
  height: auto;
  min-height: 40px;
  float: left;
  background: #000;
  font: normal 11px/12px arial, sans-serif;
  color: #fff;
}
footer .footer-links {
  width: auto;
  max-width: 920px;
  float: left;
  margin: 70px 0 0;
  padding: 0;
}
footer .footer-links li {
  width: 178px;
  height: 155px;
  float: left;
  margin: 0 128px 0 0;
}
.terms {
  margin-left: 14px;
}
footer .footer-links li h4 {
  width: 100%;
  float: left;
  margin: 0 0 2px;
  padding-bottom: 7px;
  border-bottom: 1px solid #323531;
  font: 400 14px/15px "Sintony", sans-serif;
  color: #fff;
  text-transform: uppercase;
}
footer .footer-links li h5 {
  width: 100%;
  float: left;
  margin: 6px 0 5px;
}
footer .connect-us {
  width: 214px;
  float: left;
  margin: 70px 0 0;
}
footer .connect-us .head {
  width: 100%;
  float: left;
  margin: 30px 0 0;
  font: 400 14px/15px "Sintony", sans-serif;
  color: #6f6f6f;
  text-transform: uppercase;
}
footer .connect-us .connect-link {
  float: left;
  margin: 16px 18px 0 0;
}
footer .footer-links li .links {
  float: left;
  font: 400 13px/15px "Sintony", sans-serif;
  color: #d3c070;
}
footer .copyright-contnr p,
footer .copyright-contnr span {
  float: left;
  margin: 15px 0 0;
  color: #aeaeae;
}
footer .copyright-contnr strong,
footer .copyright-contnr a,
footer .copyright-contnr p,
footer .copyright-contnr span {
  font-size: 11px;
}
footer .copyright-contnr strong {
  color: #aeaeae;
}

footer .copyright-contnr a {
  color: #770617;
}

footer .copyright-contnr span {
  float: right;
  margin: 15px 0 0;
  color: #fff;
}
footer .copyright-contnr {
  .wrapper {
    .container {
      max-width: 1134px;
    }
  }
}

.outer_application_form .mobile-nav-wrap {
  visibility: hidden;
  padding-top: 64px;
}
.outer_application_form #header li {
  width: auto;
  height: 64px;
  float: left;
  font: 400 20px/21px "Roboto Condensed", sans-serif;
  color: #ffe887;
}
.outer_application_form #header .navbar-nav {
  float: right;
}
.outer_application_form #header .navbar-nav > li > a {
  line-height: 35px;
  height: 64px;
  padding: 0 43px;
  float: left;
  font: 300 18px/60px "Roboto Condensed", sans-serif;
  color: #ffe887;
  text-transform: uppercase;
  margin: 0 1px;
}
.outer_application_form #header .navbar-nav > li > a:hover {
  color: #fff;
}
.outer_application_form #header .main_logo {
  width: 182px;
}
/*** Header & Footer - ENDS ***/

.error-text{
  margin-bottom: 7px;
  color: red;
  font-weight: 400;
}

@media only screen and (max-width: 1160px) {
  .outer_application_form .wrapper {
    padding: 0 20px;
  }
}
@media screen and (max-width: 1192px) and (min-width: 570px) {
  footer .footer-links {
    width: 70%;
  }
  footer section.container {
    width: 100%;
  }
  footer .connect-us {
    float: right;
  }
  footer .footer-links li {
    margin-right: 80px;
  }
}

@media screen and (max-width: 1048px) and (min-width: 570px) {
  .outer_application_form #header nav li a {
    padding: 0 18px !important;
  }
  .outer_application_form #header nav .container {
    float: none;
    padding-left: 170px;
  }
  .outer_application_form #header nav ul,
  nav .menu {
    float: none !important;
  }
}
@media screen and (max-width: 820px) and (min-width: 768px) {
  header .header-top .logo {
    top: -24px !important;
  }
}
@media screen and (max-width: 820px) and (min-width: 570px) {
  .outer_application_form #header nav {
    z-index: 99;
  }
  .outer_application_form #header nav .container {
    padding-left: 0px;
  }
  .outer_application_form #header .navbar-nav {
    float: none;
  }
  header .header-top .logo {
    position: absolute;
    left: 15px;
    top: 7px;
    z-index: 7;
  }
  .outer_application_form #header .navbar-nav > li > a {
    font-size: 15px;
  }
  footer .footer-links {
    width: 50%;
  }
  footer .connect-us {
    float: right;
  }
  footer .copyright-contnr .container p + strong {
    display: block;
    clear: both;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  /* .outer_application_form #header li{
        background: #070707;
        width: 100%;
    } */
  /* .outer_application_form #header .container{
        padding: 0;
    } */
  header .header-top .contact-section .buttons {
    width: auto;
  }
}
@media screen and (max-width: 767px) and (min-width: 570px) {
  header .header-top .container {
    padding: inherit;
  }
  header .header-top .contact-section .buttons {
    width: auto;
  }

  .outer_application_form #header .collapse {
    display: block;
    margin: 0;
  }
  .outer_application_form #header tr.collapse {
    display: table-row;
  }
  .outer_application_form #header tbody.collapse {
    display: table-row-group;
  }
  .outer_application_form #header .navbar-toggle {
    display: none;
  }
}
@media screen and (max-width: 569px) {
  .outer_application_form #header li {
    background: #070707;
    width: 100%;
  }
  .outer_application_form #header .container {
    padding: 0;
  }
  header .header-top {
    min-height: 153px;
  }
  header .header-top .logo {
    z-index: 99;
    position: absolute;
    left: 20px;
    top: 20px;
  }
  header .header-top .contact-section {
    width: auto;
    margin: 20px 20px 0px;
  }
  header .header-top .contact-section .buttons:first-child,
  header .header-top .contact-section .buttons:first-child.selected {
    background-image: url(../images/icon_contactus.png) !important;
  }
  header .header-top .contact-section .buttons,
  header .header-top .contact-section .buttons:hover,
  header .header-top .contact-section .buttons.selected {
    background-image: url(../images/icon_aboutus.png) !important;
  }
  header .header-top .contact-section .buttons.icon-emplogin,
  header .header-top .contact-section .buttons.icon-emplogin:hover,
  header .header-top .contact-section .buttons.icon-emplogin.selected {
    background-image: url(../images/icon_emplogin.png) !important;
  }
  header .header-top .contact-section .buttons {
    width: 29px;
    height: 29px;
    padding: 0;
    margin: 0;
    font-size: 0;
    margin-left: 10px;
  }
  header .header-top .contact-section .buttons,
  header .header-top .contact-section .buttons:hover {
    background-position: center center !important;
    background-repeat: no-repeat !important;
  }
  header .header-top .contact-section .tel {
    display: none;
  }
  footer .copyright-contnr p {
    float: none;
    margin: 0 0 0 0;
    color: #fff;
    line-height: 26px;
    margin-bottom: 21px;
  }
  footer .copyright-contnr strong {
    color: #fff;
  }
  footer .footer-links li h4 {
    padding-bottom: 6px;
  }
  footer .copyright-contnr {
    overflow: hidden;
    padding: 35px 20px 29px;
    width: auto;
    height: auto;
    float: none;
  }
  footer .copyright-contnr .wrapper {
    padding: 0;
  }
  footer .copyright-contnr span {
    float: none;
    color: #fff;
  }
  footer .connect-us {
    margin: 0;
  }
  footer .footer-links li {
    height: auto;
    margin: 0 0 21px 0;
    overflow: hidden;
    float: none;
  }
  footer .footer-links {
    margin: 28px 0 0;
  }
  .outer_application_form .header-top .wrapper {
    padding: 0;
  }
  .outer_application_form #header .navbar-inverse .navbar-toggle {
    border-color: #7f7f7f;
  }
  .outer_application_form #header .navbar-toggle {
    position: relative;
    float: right;
    padding: 5px 4px;
    margin-top: 17px;
    // margin-right: 25px;
    margin-right: 3px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid #fff;
    border-radius: 0;
  }
  .outer_application_form #header .navbar-toggle .icon-bar {
    display: block;
    width: 20px;
    height: 3px;
    border-radius: 1px;
  }
  .outer_application_form #header .navbar-inverse .navbar-toggle .icon-bar {
    background-color: #878787;
  }
}

@media screen and (min-width: 768px) {
  .navbar-collapse {
    padding: 0 !important ;
  }
}

// New Header styels

.nvam-btn {
  padding: 10px 16px;
  background-color: #00703C;
  color: white;
  text-decoration: none !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  border-radius: 3px;
  transition: all 0.4s;
  &:hover {
    color: white;
    background-color: #005C31;
  }
}

.nvam-header-wrapper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px 16px 0px;
    @media (max-width: 1199px) {
      max-width: 970px;
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (max-width: 991px) {
      max-width: 750px;
    }
    .nvam-brand-wrapper {
      max-width: 153px;
      margin-right: 30px;
      img {
        max-width: 100%;
      }
      @media (max-width: 767px) {
        margin: 0px auto 15px auto;
      }
    }
    .nvam-right-side {
      display: inline-flex;
      align-items: center;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        flex: 0 0 100%;
        justify-content: center;
      }
      .tel {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #00703C;
        margin-right: 38px;
        @media (max-width: 767px) {
          flex: 0 0 100%;
          margin-right: 0px;
          margin-bottom: 15px;
          justify-content: center;
        }
        .icon{
          margin-right: 4px;
        }
      }
    }
  }
}

// New Footer styels
.d-flex.d-flex.d-flex.d-flex{
  display: flex;
}
.d-none.d-none.d-none.d-none{
  display: none;
}

.flex-center.flex-center{
  justify-content: center;
  align-items: center;
}
.nvam-footer-wrapper {
  background-color: #EEF0F2;
  padding: 40px 0px 0px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  *{
    font-family: 'Proxima Nova', sans-serif;
  }
 .wrapper{
   flex: 0 0 100%;
   max-width: 840px;
   @media screen and (max-width: 992px){
      max-width: 454px;
   }
   .row{
     &.two-col-row{
      @media screen and (min-width: 993px){
        display: flex;
      }
     }
    .col-md-6{
      & + .col-md-6{
        @media screen and (min-width: 993px){
          border-left: 1px solid  rgba(0, 0, 0, 0.12);
        }
      }
    }

    .col-md-8{
      padding-right: 44px;
      padding-left: 36px;
      @media screen and (max-width: 992px){
        margin-bottom: 50px;
      }
      @media screen and (max-width: 1160px){
        padding-left: 25px;
        padding-right: 25px;
      }
    }

    .col-md-4{
      padding-left: 44px;
      padding-right: 36px;
      @media screen and (max-width: 1160px){
        padding-left: 25px;
        padding-right: 25px;
      }
    }
   }
 }
  h4 {
    font-size: 18px;
    line-height: 1.2;
    color: rgba($color: #202020, $alpha: 0.87);
    margin: 0px 0px 12px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-bottom: 15px;
    color: rgba(32, 32, 32, 0.87);
    font-weight: 700;
    font-family: 'proxima-nova',sans-serif;
  }
  h5{
    font-size: 15px;
    color: rgba(32, 32, 32, 0.87);
    font-weight: 700;
    font-family: 'proxima-nova',sans-serif;
    margin-bottom: 20px;
  }
  p {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 0;
    & + p{
      margin-top: 16px;
    }
    font-weight: 400;
    max-width: 180px;
    margin-left: auto;
    margin-right: auto;
    color: rgba(32, 32, 32, 0.87);
    line-height: 20px;
  }
  .tel {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #00703C;
    font-family: 'proxima-nova',sans-serif;
    .icon{
      margin-right: 4px;
    }
  }
  .nvam-footer-social-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 993px){
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      padding-top: 10px;
      margin-top: 14px;
    }
    .nvam-social-item {
      display: flex;
      margin: 0px 8px;
    }
  }
  .nvam-foot-copyright {
    margin: 40px 0px 0px;
    background-color: #DCE0E4;
    padding: 17px 15px 6px 15px;
    @media screen and (max-width: 992px){
      padding-left: calc(50vw - 200px);
      padding-right: calc(50vw - 200px);
    }

    @media screen and (max-width: 454px){
      padding-left: 30px;
      padding-right: 30px;
    }

    text-align: center;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    max-width: 100%;
    color: rgba($color: #202020, $alpha: 0.87);
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}