.row-main {
  float: left;
  margin-bottom: 60px;
  border-top: solid 1px #f1f1f1;
  padding: 12px 0;
  border-bottom: solid 1px #f1f1f1;
  @media screen and (min-width:768px){
    width: 100%;
  }
}
.form-data-loader {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
}

.aplcn-body {
  position: relative;
}
.status {
  text-transform: capitalize;
}
.status- {
  &received {
    color: #3f7e39;
  }
  &:denied {
    color: rgb(71, 20, 20);
  }
}

.back-to-applications {
  color: #00703C;
  border: solid 1px #00703C;
  padding: 6px 15px;
  font-weight: 500 ;
  text-transform: uppercase;
  text-decoration: none !important;
  float: left;
  border-radius: 3px;
  .fa{
    margin-right: 4px;
    font-weight: normal ;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: #005C31;
    color: white ;
  }
}

.aplcn-button-label-edit{
  font-size: 13px;
  font-weight: normal;
  position: relative;
  top: -1px;
}
.aplcn-edit-icon{
  font-size: 15px;
}
.aplcn-open-aplication-tab{
  width: initial !important;
}

.aplcn-detail-block .aplcn-open-aplication-tab{
  position: static !important;
  height: unset !important;
}

/*Filters List */ 
.aplcn-filters{
  width: 100% ;
  display: flex ;
  border-bottom: solid 1px #f1f1f1;
  padding: 0px 0px 10px ;
  .aplcn-filter-label{
    max-width: 60px;
  }
  .aplcn-filter-label,.aplcn-filter-box-wrapper{
    display: flex ;
    flex: auto ;
    align-items: center ;
  }
  .aplcn-filter-box-wrapper{
    select{
      max-width: 200px;
      height: 35px;
      border: solid 1px #e6e6e6;
      // border-top: none ;
      // border-left: none ;
      // border-right: none ;
    }
  }
}
.container>.navbar-header{
  width: 100% ;
}

.container .history-list{
  margin-bottom: 50px;
}
.container .history-list .history-item{
  margin-bottom: 20px;
}
.container .history-list .history-item:last-child{
  margin-bottom: 0px;
}

.container .history-list .history-item .row-wrap{
  background: #f1f1f1;
  padding: 20px 30px;
}

.container .history-list .title{
  margin-bottom: 30px;
}

.container .history-list .history-item .sub-title{
  font-weight:300;
  margin-bottom: 5px;
}

.green-btn{
  background: #3f7e39;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 185px;
  height: 40px;
  outline: none;
  font-weight: 600;
}

.aplcn-detail-block .aplcn-open-aplication-tab {
  margin-top: 10px;
}

@media screen and (max-width:767px){
  .aplcn-detail-block .aplcn-open-aplication-tab {
    top: -14px;
  }
  .container .history-list .history-item .row-wrap .row > div{
    margin-bottom: 10px;
  }
  
}