.aplcn-tearms-and-conditions {
  background-color: #f1f6f1;
  padding: 20px;
  p {
    font-weight: normal;
    // font-size: 16px;
    color: #6a6a6a;
  }
}

.aplcn-green-check {
  input {
    &:checked + label {
      &:before {
        background-color: #3f7e39 !important;
        border-color: #3f7e39 !important;
      }
    }
  }
}

.aplcn-body .aplcn-checklist li input[type="checkbox"],
.aplcn-body .aplcn-checklist li input[type="radio"] {
  position: relative;
  left: 11px !important;
}
