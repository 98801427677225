.aplcn-page-404{
    display: flex;
    padding: 0 15px;
    min-height: 31vh;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    flex-direction: column ;
    .aplcn-404-title{
        font-weight: bolder ;
    }
    .aplcn-404-message{
font-size: 18px;
color: #999 ;
    }
}