button.add-more-sections{
    background: #f8f8f8;
    width: 100%;
    border: none;
    padding: 10px 15px;
    color: #000;
    &:hover,&:focus,&:active{
        background: darken(#f8f8f8,4);
    }
}
.pad-v-2{
    padding: 2rem 0;
}