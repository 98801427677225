.aplcn-form-section-block {
  .dropzone {
    height: 90px;
    border: dashed 1px #202020;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 3px;
  }
}

ul.inline-radios {
  li {
    display: inline-block !important;
    margin-right: 25px !important ;
    label {
      padding-left: 31px !important;
    }
  }
}

.aplcn-no-files-selected {
  display: block;
  padding: 20px;
  text-align: left;
}

.aplcn-qu-upload-error {
  float: left;
  width: 100%;
  padding: 14px 0 20px;
  font-weight: normal;
  color: #ef8282;
}

@keyframes load {
  0% {
    width: 0%;
  }
  99% {
    width: 100%;
  }
  100% {
    display: none;
  }
}

.aplcn-file-loaded {
  .aplcn-file-uploading-percentage .aplcn-file-loader .aplcn-inner-per {
    animation: load;
    animation-duration: 4s;
  }
}

.react-confirm-alert-body {
  h1 {
    &:first-child {
      font-size: 25px;
    }
  }
}
.react-confirm-alert-overlay {
  opacity: 1 !important;
  animation: initial !important ;
}
.aplcn-desc {
  font-weight: normal;
  color: #6a6a6a;
  font-size: 16px;
}
.aplcn-note-text-wrapper {
  textarea {
    width: 100%;
    font-weight: normal;
    padding: 20px;
    border: solid 1px #cfd3d4;
    border-radius: 3px;
    resize: none;
    min-height: 80px;
  }
}
h5.aplcn-is-graduate {
  font-size: 16px;
}
