.aplcn-error-field{
    font-size: 13px;
    font-weight: normal;
    color: #ec6e6e;
    padding: 0px 0 2px;
    position: relative;
    top: -8px;
}

.loginProgress{
    text-align: center ;
    padding-top: 15px;
    user-select: none ;
}
.aplcn-info-icon{
    color: darken(#ec6e6e,5);
}

.aplcn-social-media-auth{
    a{
        color: #fff;
        line-height: 40px;
        text-align: center ;
        transition: all ease .3s ;
        &:hover,&:focus,&:active{
            background-color: rgb(53, 53, 53) ;
        }
    }
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.35) !important;
}
.aplcn-form-row{
    a{
        text-decoration: none;
    }
    .link-green{
        color: #3f7e39;
    }
}
#react-confirm-alert{
    position: relative;
    z-index: 5000;
}

// New styles

.nvam-title {
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.2;
    margin: 0px 0px 30px;
    color: #292929;
    letter-spacing: -0.01em;;
    font-family: 'Montserrat', sans-serif;
}

.aplcn-login-box-wrapper .aplcn-header-tabs .aplcn-header-tab {
    @media (max-width: 320px) {
        padding: 0px 10px !important;
    }
}


.aplcn-body input[type=submit] {
    background-color: #00703C !important;
    border-color: #00703C !important;
    &:hover {
        background-color: #005C31 !important;
    }
}