.react-datepicker__day {
  width: 30px !important;
  height: 30px !important ;
  line-height: 30px !important ;
  font-weight: normal !important ;
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
  width: 100%;
}
.react-datepicker__day-name {
  width: 35px !important;
  color: #fff !important ;
  padding: 0px 0 5px !important;
}
.react-datepicker__header {
  padding-top: 12px !important ;
}
.react-datepicker__current-month {
  font-size: 17px !important;
  text-transform: uppercase;
  padding: 0 0 5px !important;
  color: #fff !important ;
}

.react-datepicker__header {
  background-color: #272727 !important;
}
.react-datepicker {
  border-color: #cccccc !important ;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #272727 !important;
}

.react-datepicker__navigation {
  top: 17px !important;
}

.react-datepicker__year-dropdown-container--scroll {
  font-size: 14px;
  color: #fff;
  // position: relative;
  margin-top: -7px !important;
}
.react-datepicker__year-dropdown {
  background-color: #ffffff !important;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #d0c4c4;
  color: #272727;
  box-shadow: 0 2px 9px -2px rgba(0, 0, 0, 0.5);
  font-weight: normal !important ;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px !important;
  border-bottom-color: #272727 !important;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #272727 !important;
  top: 2px !important;
}
.nav.navbar-nav {
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  color: rgb(255, 232, 135);
  cursor: pointer;
  display: block;
  float: left;
  font-family: "Roboto Condensed", sans-serif;
}
.outer_application_form #header {
  // .logo {
  //   position: absolute ;
  //   left: 20px;
  //   @media screen and (max-width: 993px) {
  //     top: 15px !important ;

  //     img {
  //       width: 120px;
  //     }
  //   }
  // }
}
input {
  appearance: none;
}

header .header-top .contact-section .tel {
  // display: none;
}
.header-top {
  .wrapper {
    .container {
      // background-color: #f00 ;
    }
  }
}

.navbar-header {
  // float: left;
  // width: 100% ;
  .navbar-toggle {
    // margin-right: 5px !important ;
  }
}
// header .header-top .logo{
//   // display: none ;
// }

// @media screen and (max-width:569px){
//   .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
//     border-color: #101010;
//     width: 120%;
//     margin: 0 -2px;
//   }
// }
