.aplcn-form-data-prefetch{
    float: left;
    width: 100%;
    text-align: center;
    display: flex ;
    justify-content: center ;
    align-items: center ;
    height: 150px;
    
}

.aplcn-header-info{
    max-width: 650px !important;
}
